import {PublicKey} from "@solana/web3.js";
import usdcLogo from './logo/USDC_LOGO.png';
import solLogo from './logo/SOL_LOGO.png';
import hntLogo from './logo/HNT_LOGO.png';
import jtoLogo from './logo/JTO_LOGO.png';
import bonkLogo from './logo/BONK_LOGO.png';
import rayLogo from './logo/RAY_LOGO.png';
import jupLogo from './logo/JUP_LOGO.png';
import pythLogo from './logo/PYTH_LOGO.png';
import renderLogo from './logo/RENDER_LOGO.png';
import auryLogo from './logo/AURY_LOGO.png';
import honeyLogo from './logo/HONEY_LOGO.png';
import orcaLogo from './logo/ORCA_LOGO.png';
import usdtLogo from './logo/USDT_LOGO.png';
import wifLogo from './logo/WIF_LOGO.png';
import wenLogo from './logo/WEN_LOGO.png';
import nosLogo from './logo/NOS_LOGO.png';
import shdwLogo from './logo/SDHW_LOGO.png';
import wLogo from './logo/W_LOGO.png';
import kmnoLogo from './logo/KMNO_LOGO.png';
import borgLogo from './logo/BORG_LOGO.png';
import motherLogo from './logo/MOTHER_LOGO.png';
import wxmLogo from './logo/WXM_LOGO.png';
import zexLogo from './logo/ZEX_LOGO.png';
import bsolLogo from './logo/BSOL_LOGO.png';
import jitosolLogo from './logo/JITOSOL_LOGO.png';
import msolLogo from './logo/MSOL_LOGO.png';
import cloudLogo from './logo/CLOUD_LOGO.png';
import xbgLogo from './logo/XBG_LOGO.png';
import alephLogo from './logo/ALEPH_LOGO.png';
import vchfLogo from './logo/VCHF_LOGO.png';
import veurLogo from './logo/VEUR_LOGO.png';
import driftLogo from './logo/DRIFT_LOGO.png';
import ponkeLogo from './logo/PONKE_LOGO.png';
import tnsrLogo from './logo/TNSR_LOGO.png';
import natixLogo from './logo/NATIX_LOGO.png';
import neonLogo from './logo/NEON.png';
import dashaLogo from './logo/DASHA.png'
import retardioLogo from './logo/RETARDIO_LOGO.png'
import michiLogo from './logo/MICHI_LOGO.png'
import selfieLogo from './logo/SELFIEDOG_LOGO.png'
import hammyLogo from './logo/HAMMY_LOGO.png'
import miniLogo from './logo/MINI_LOGO.png'
import sigmaLogo from './logo/SIGMA_LOGO.png'
import mumuLogo from './logo/MUMU_LOGO.png'
import billyLogo from './logo/BILLY_LOGO.png'
import spx6900Logo from './logo/SPX6900_LOGO.png'
import blzeLogo from './logo/BLZE_LOGO.png';
import crownLogo from './logo/CROWN_LOGO.png';
import grassLogo from './logo/GRASS_LOGO.png';
import guacLogo from './logo/GUAC_LOGO.png';
import jlpLogo from './logo/JLP_LOGO.png';
import lockinLogo from './logo/LOCKIN_LOGO.png';
import lstLogo from './logo/LST_LOGO.png';
import mewLogo from './logo/MEW_LOGO.png';
import mndeLogo from './logo/MNDE_LOGO.png';
import myroLogo from './logo/MYRO_LOGO.png';
import oreLogo from './logo/ORE_LOGO.png';
import popcatLogo from './logo/POPCAT_LOGO.png';
import uxdLogo from './logo/UXD_LOGO.png';
import zerebroLogo from './logo/ZEREBRO_LOGO.png';
import chillguyLogo from './logo/CHILLGUY_LOGO.png';
import gigaLogo from './logo/GIGA_LOGO.png';
import tookerLogo from './logo/TOOKER_LOGO.png';
import goatLogo from './logo/GOAT_LOGO.png';
import brettLogo from './logo/BRETT_LOGO.png';
import moodengLogo from './logo/MOODENG_LOGO.png';
import trempLogo from './logo/TREMP_LOGO.png';
import harambeLogo from './logo/HARAMBE_LOGO.png';
import metaLogo from './logo/META_LOGO.png';
import borgyLogo from './logo/BORGY_LOGO.png';
import bitcoinpotterLogo from './logo/BITCOINPOTTER_LOGO.png';
import ioLogo from './logo/IO_LOGO.png';
import meLogo from './logo/ME_LOGO.png';
import pnutLogo from './logo/PNUT_LOGO.png';
import scfLogo from './logo/SCF_LOGO.png';
import usaLogo from './logo/USA_LOGO.png';
import penguLogo from './logo/PUDGY_LOGO.png';
import agaLogo from './logo/AGA_LOGO.png';
import solidLogo from './logo/SOLID_LOGO.png';
import chonkyLogo from './logo/CHONKY_LOGO.png';
import moemateLogo from './logo/MOE_LOGO.png';
import agentSaiLogo from './logo/AGENT_LOGO.png';
import dogamiLogo from './logo/DOGAMI_LOGO.png';
import smartdexLogo from './logo/SDEX_LOGO.png';
import aiPumpLogo from './logo/AIPUMP_LOGO.png';
import matesLogo from './logo/MATES_LOGO.png';
import fartcoinLogo from './logo/FARTCOIN_LOGO.png';
import magaLogo from './logo/MAGA_LOGO.png';
import pippinLogo from './logo/PIPPIN_LOGO.png';
import createLogo from './logo/CREATE_LOGO.png';
import neurLogo from './logo/NEUR_LOGO.png';
import griffainLogo from './logo/GRIFFAIN_LOGO.png';
import griftLogo from './logo/GRIFT_LOGO.png';
import gnzLogo from './logo/GNZ_LOGO.png';
import trumpLogo from './logo/TRUMP_LOGO.png';
import melaniaLogo from './logo/MELANIA_LOGO.png';
import snaiLogo from './logo/SNAI_LOGO.png';
import avaLogo from './logo/AVA_LOGO.png';
import sendLogo from './logo/SEND_LOGO.png';
import buttholeLogo from './logo/BUTTHOLE_LOGO.png';
import yneLogo from './logo/YNE_LOGO.png';
import arxivLogo from './logo/ARXIV_LOGO.png';
import chatoshiLogo from './logo/CHATOSHI_LOGO.png';

export const proxyProgramID = new PublicKey(process.env.REACT_APP_OMS_PROXY_PROGRAM_ID as string);
export const noncePublickey = new PublicKey(process.env.REACT_APP_NONCE_PUBKEY as string);

export const phoenixProgramId = "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY";
export const whirlpoolProgramId = "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc"
export const raydiumProgramId = "CAMMCzo5YL8w4VFF8KVHrK22GGUsp5VTaW7grrKgrWqK"
export const lifinityProgramId = "2wT8Yq49kHgDzXuPxZSaeLaH1qbmGXtEyPy64bL7aD3c"
export const raydiumCpmmProgramId = "CPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"
export const meteoraProgramId = "LBUZKhRxPF3XUpBCjp4YzTKgLccjZhTSDM9YuVaPwxo"
export const openbookV2ProgramId = "opnb2LAfJYbRMAHHvqjCwQxanZn7ReEHp1k81EohpZb"
export const raydiumAmmV4ProgramId = "675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8"

export const cryptoLogos = {
    USDC: usdcLogo,
    SOL: solLogo,
    HNT: hntLogo,
    JTO: jtoLogo,
    BONK: bonkLogo,
    RAY: rayLogo,
    JUP: jupLogo,
    PYTH: pythLogo,
    RENDER: renderLogo,
    AURY: auryLogo,
    HONEY: honeyLogo,
    ORCA: orcaLogo,
    USDT: usdtLogo,
    WIF: wifLogo,
    WEN: wenLogo,
    NOS: nosLogo,
    SHDW: shdwLogo,
    W: wLogo,
    KMNO: kmnoLogo,
    BORG: borgLogo,
    MOTHER: motherLogo,
    WXM: wxmLogo,
    ZEX: zexLogo,
    BSOL: bsolLogo,
    JITOSOL: jitosolLogo,
    MSOL: msolLogo,
    CLOUD: cloudLogo,
    XBG: xbgLogo,
    ALEPH: alephLogo,
    VCHF: vchfLogo,
    VEUR: veurLogo,
    DRIFT: driftLogo,
    PONKE: ponkeLogo,
    TNSR: tnsrLogo,
    NATIX: natixLogo,
    SPX6900: spx6900Logo,
    NEON: neonLogo,
    DASHA: dashaLogo,
    RETARDIO: retardioLogo,
    MICHI: michiLogo,
    SELFIE: selfieLogo,
    HAMMY: hammyLogo,
    MINI: miniLogo,
    SIGMA: sigmaLogo,
    MUMU: mumuLogo,
    BILLY: billyLogo,
    BLZE: blzeLogo,
    CROWN: crownLogo,
    GRASS: grassLogo,
    GUAC: guacLogo,
    JLP: jlpLogo,
    LOCKIN: lockinLogo,
    LST: lstLogo,
    MEW: mewLogo,
    MNDE: mndeLogo,
    MYRO: myroLogo,
    ORE: oreLogo,
    POPCAT: popcatLogo,
    UXD: uxdLogo,
    ZEREBRO: zerebroLogo,
    CHILLGUY: chillguyLogo,
    GIGASOL: gigaLogo,
    TOOKERSOL: tookerLogo,
    GOATSOL: goatLogo,
    BRETTSOL: brettLogo,
    MOODENGSOL: moodengLogo,
    TREMPSOL: trempLogo,
    HARAMBESOL: harambeLogo,
    METASOL: metaLogo,
    BORGY: borgyLogo,
    BITCOINPOTTER: bitcoinpotterLogo,
    IO: ioLogo,
    ME: meLogo,
    PNUT: pnutLogo,
    SCF: scfLogo,
    USA: usaLogo,
    PENGU: penguLogo,
    AGA: agaLogo,
    SOLID: solidLogo,
    CHONKY: chonkyLogo,
    MOE: moemateLogo,
    AGENTSAI: agentSaiLogo,
    DOGAMI: dogamiLogo,
    SMARDEX: smartdexLogo,
    AIPUMP: aiPumpLogo,
    MATES: matesLogo,
    FARTCOIN: fartcoinLogo,
    MAGA: magaLogo,
    PIPPIN: pippinLogo,
    CREATE: createLogo,
    NEUR: neurLogo,
    GRIFFAIN: griffainLogo,
    GRIFT: griftLogo,
    GNZ: gnzLogo,
    TRUMP: trumpLogo,
    MELANIA: melaniaLogo,
    SNAI: snaiLogo,
    AVA: avaLogo,
    SEND: sendLogo,
    BUTTHOLE: buttholeLogo,
    YNE: yneLogo,
    ARXIV: arxivLogo,
    CHATOSHI: chatoshiLogo
};

export interface SupportedAsset {
    value: string;
    label: string;
    img: string;
    mint: PublicKey;
    balance?: string;
    decimals: number;
}

export interface SolanaFmInterface {
    blockTime: number;
    confirmationStatus: string;
    err: string;
    memo: string;
    signature: string;
    slot: number;
}

export interface TxHistoryInterface {
    signature: string;
    intruction: string | null;
}

export interface CoinAddressInterface {
    usdc: PublicKey,
    sol: PublicKey,
    hnt: PublicKey,
    jto: PublicKey,
    bonk: PublicKey,
    ray: PublicKey,
    jup: PublicKey,
    pyth: PublicKey,
    render: PublicKey,
    aury: PublicKey,
    honey: PublicKey,
    orca: PublicKey,
    usdt: PublicKey,
    wif: PublicKey,
    wen: PublicKey,
    nos: PublicKey,
    shdw: PublicKey,
    w: PublicKey,
    kmno: PublicKey,
    borg: PublicKey,
    mother: PublicKey,
    wxm: PublicKey,
    zex: PublicKey,
    bsol: PublicKey,
    jitosol: PublicKey,
    msol: PublicKey,
    cloud: PublicKey,
    xbg: PublicKey,
    aleph: PublicKey,
    vchf: PublicKey,
    veur: PublicKey,
    ponke: PublicKey,
    tnsr: PublicKey,
    natix: PublicKey,
    drift: PublicKey,
    neon: PublicKey,
    dasha: PublicKey,
    spx6900: PublicKey,
    retardio: PublicKey,
    michi: PublicKey,
    selfie: PublicKey,
    hammy: PublicKey,
    mini: PublicKey,
    sigma: PublicKey,
    mumu: PublicKey,
    billy: PublicKey,
    blze: PublicKey,
    crown: PublicKey,
    grass: PublicKey,
    guac: PublicKey,
    jlp: PublicKey,
    lockin: PublicKey,
    lst: PublicKey,
    mew: PublicKey,
    mnde: PublicKey,
    myro: PublicKey,
    ore: PublicKey,
    popcat: PublicKey,
    uxd: PublicKey,
    zerebro: PublicKey,
    chillguy: PublicKey,
    gigasol: PublicKey,
    tookersol: PublicKey,
    goatsol: PublicKey,
    brettsol: PublicKey,
    moodengsol: PublicKey,
    trempsol: PublicKey,
    harambesol: PublicKey,
    metasol: PublicKey,
    borgy: PublicKey,
    bitcoinpotter: PublicKey,
    io: PublicKey,
    me: PublicKey,
    pnut: PublicKey,
    scf: PublicKey,
    usa: PublicKey,
    pengu: PublicKey,
    aga: PublicKey,
    solid: PublicKey,
    chonky: PublicKey,
    moe: PublicKey,
    agentSai: PublicKey,
    dogami: PublicKey,
    smartdex: PublicKey,
    aiPump: PublicKey,
    mates: PublicKey,
    fartcoin: PublicKey,
    maga: PublicKey,
    pippin: PublicKey,
    create: PublicKey,
    neur: PublicKey,
    griffain: PublicKey,
    grift: PublicKey,
    gnz: PublicKey,
    trump: PublicKey,
    melania: PublicKey,
    snai: PublicKey,
    ava: PublicKey,
    send: PublicKey,
    butthole: PublicKey,
    yne: PublicKey,
    arxiv: PublicKey,
    chatoshi: PublicKey
}
export const explorerCLusters:  Record<string,string> = {
    MAINNET: "",
    DEVNET: "cluster=devnet"
}
export const coinAddresses: Record<string, CoinAddressInterface> = {
    MAINNET: {
        usdc: new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'),
        sol: new PublicKey('So11111111111111111111111111111111111111112'),
        hnt: new PublicKey('hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux'),
        jto: new PublicKey('jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL'),
        bonk: new PublicKey('DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263'),
        ray: new PublicKey('4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R'),
        jup: new PublicKey('JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN'),
        pyth: new PublicKey('HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3'),
        render: new PublicKey('rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof'),
        aury: new PublicKey("AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP"),
        honey: new PublicKey("4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy"),
        orca: new PublicKey("orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE"),
        usdt: new PublicKey("Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"),
        wif: new PublicKey("EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm"),
        wen: new PublicKey("WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk"),
        nos: new PublicKey("nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7"),
        shdw: new PublicKey("SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y"),
        w: new PublicKey("85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ"),
        kmno: new PublicKey("KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS"),
        borg: new PublicKey("3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z"),
        mother: new PublicKey("3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN"),
        wxm: new PublicKey("wxmJYe17a2oGJZJ1wDe6ZyRKUKmrLj2pJsavEdTVhPP"),
        zex: new PublicKey("ZEXy1pqteRu3n13kdyh4LwPQknkFk3GzmMYMuNadWPo"),
        bsol: new PublicKey("bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1"),
        jitosol: new PublicKey("J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn"),
        msol: new PublicKey("mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So"),
        cloud: new PublicKey("CLoUDKc4Ane7HeQcPpE3YHnznRxhMimJ4MyaUqyHFzAu"),
        xbg: new PublicKey("XBGdqJ9P175hCC1LangCEyXWNeCPHaKWA17tymz2PrY"),
        aleph: new PublicKey("3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x"),
        vchf: new PublicKey("AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch"),
        veur: new PublicKey("C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519"),
        drift: new PublicKey("DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7"),
        tnsr: new PublicKey("TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6"),
        ponke: new PublicKey("5z3EqYQo9HiCEs3R84RCDMu2n7anpDMxRhdK8PSWmrRC"),
        natix: new PublicKey("FRySi8LPkuByB7VPSCCggxpewFUeeJiwEGRKKuhwpKcX"),
        neon: new PublicKey("NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44"),
        dasha: new PublicKey("FQ1tyso61AH1tzodyJfSwmzsD3GToybbRNoZxUBz21p8"),
        spx6900: new PublicKey("J3NKxxXZcnNiMjKw9hYb2K4LUxgwB6t1FtPtQVsv3KFr"),
        retardio: new PublicKey("6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx"),
        michi: new PublicKey("5mbK36SZ7J19An8jFochhQS4of8g6BwUjbeCSxBSoWdp"),
        selfie: new PublicKey("9WPTUkh8fKuCnepRWoPYLH3aK9gSjPHFDenBq2X1Czdp"),
        hammy: new PublicKey("26KMQVgDUoB6rEfnJ51yAABWWJND8uMtpnQgsHQ64Udr"),
        mini: new PublicKey("2JcXacFwt9mVAwBQ5nZkYwCyXQkRcdsYrDXn6hj22SbP"),
        sigma: new PublicKey("5SVG3T9CNQsm2kEwzbRq6hASqh1oGfjqTtLXYUibpump"),
        mumu: new PublicKey("5LafQUrVco6o7KMz42eqVEJ9LW31StPyGjeeu5sKoMtA"),
        billy: new PublicKey("3B5wuUrMEi5yATD7on46hKfej3pfmd7t1RKgrsN3pump"),
        blze: new PublicKey('BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA'),
        crown: new PublicKey('GDfnEsia2WLAW5t8yx2X5j2mkfA74i5kwGdDuZHt7XmG'),
        grass: new PublicKey('Grass7B4RdKfBCjTKgSqnXkqjwiGvQyFbuSCUJr3XXjs'),
        guac: new PublicKey('AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR'),
        jlp: new PublicKey('27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4'),
        lockin: new PublicKey('8Ki8DpuWNxu9VsS3kQbarsCWMcFGWkzzA8pUPto9zBd5'),
        lst: new PublicKey('LSTxxxnJzKDFSLr4dUkPcmCf5VyryEqzPLz5j4bpxFp'),
        mew: new PublicKey('MEW1gQWJ3nEXg2qgERiKu7FAFj79PHvQVREQUzScPP5'),
        mnde: new PublicKey('MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey'),
        myro: new PublicKey('HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4'),
        ore: new PublicKey('oreoU2P8bN6jkk3jbaiVxYnG1dCXcYxwhwyK9jSybcp'),
        popcat: new PublicKey('7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr'),
        uxd: new PublicKey('7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT'),
        zerebro: new PublicKey('8x5VqbHA8D7NkD52uNuS5nnt3PwA8pLD34ymskeSo2Wn'),
        chillguy: new PublicKey('Df6yfrKC8kZE3KNkrHERKzAetSxbrWeniQfyJY4Jpump'),
        gigasol: new PublicKey('63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9'),
        tookersol: new PublicKey('9EYScpiysGnEimnQPzazr7Jn9GVfxFYzgTEj85hV9L6U'),
        goatsol: new PublicKey('CzLSujWBLFsSjncfkh59rUFqvafWcY5tzedWJSuypump'),
        brettsol: new PublicKey('DxtssVdyYe4wWE5f5zEgx2NqtDFbVL3ABGY62WCycHWg'),
        moodengsol: new PublicKey('ED5nyyWEzpPPiWimP8vYm7sD7TD3LAt3Q3gRTWHzPJBY'),
        trempsol: new PublicKey('FU1q8vJpZNUrmqsciSjp8bAKKidGsLmouB8CBdf8TKQv'),
        harambesol: new PublicKey('Fch1oixTPri8zxBnmdCEADoJW2toyFHxqDZacQkwdvSP'),
        metasol: new PublicKey('METADDFL6wWMWEoKTFJwcThTbUmtarRJZjRpzUvkxhr'),
        borgy: new PublicKey("BorGY4ub2Fz4RLboGxnuxWdZts7EKhUTB624AFmfCgX"),
        bitcoinpotter: new PublicKey('CTgiaZUK12kCcB8sosn4Nt2NZtzLgtPqDwyQyr2syATC'),
        io: new PublicKey('BZLbGTNCSFfoth2GYDtwr7e4imWzpR5jqcUuGEwr646K'),
        me: new PublicKey('MEFNBXixkEbait3xn9bkm8WsJzXtVsaJEn4c8Sam21u'),
        pnut: new PublicKey('2qEHjDLDLbuBgRYvsxhc5D6uDWAivNFZGan56P1tpump'),
        scf: new PublicKey('GiG7Hr61RVm4CSUxJmgiCoySFQtdiwxtqf64MsRppump'),
        usa: new PublicKey('69kdRLyP5DTRkpHraaSZAQbWmAwzF9guKjZfzMXzcbAs'),
        pengu: new PublicKey('2zMMhcVQEXDtdE6vsFS7S7D5oUodfJHE8vd1gnBouauv'),
        aga: new PublicKey('AGAxefyrPTi63FGL2ukJUTBtLJStDpiXMdtLRWvzambv'),
        solid: new PublicKey('SoLiDMWBct5TurG1LNcocemBK7QmTn4P33GSrRrcd2n'),
        chonky: new PublicKey('H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU'),
        moe: new PublicKey('8xzoj8mVmXtBQm6d2euJGFPvQ2QsTV5R8cdexi2qpump'),
        agentSai: new PublicKey('Ag5mMdBSxm8J893RazD9PGkxWKJ5o3UKdWW4ZmJAey9w'),
        dogami: new PublicKey('9o8MnTiZs8i7ahF4MVVW6yEgDso6ksCVXZg4BdcWo8hg'),
        smartdex: new PublicKey('9dzSzFvPsKDoY2gdWErsuz2H1o4tbzvgBhrNZ9cvkD2j'),
        aiPump: new PublicKey('2w3A2P5juwg234spHKfps7WReWoVmujtErqjaZm9VaiP'),
        mates: new PublicKey('MAtesZXcU38mgPuteRFo9ApCauYRRuwn3xf1cs5y7ZX'),
        fartcoin: new PublicKey('9BB6NFEcjBCtnNLFko2FqVQBq8HHM13kCyYcdQbgpump'),
        maga: new PublicKey('HaP8r3ksG76PhQLTqR8FYBeNiQpejcFbQmiHbg787Ut1'),
        pippin: new PublicKey('Dfh5DzRgSvvCFDoYc2ciTkMrbDfRKybA4SoFbPmApump'),
        create: new PublicKey('92crE7qiX5T7VtiXhCeagfo1E81UtyguiXM7qCi7pump'),
        neur: new PublicKey('3N2ETvNpPNAxhcaXgkhKoY1yDnQfs41Wnxsx5qNJpump'),
        griffain: new PublicKey('KENJSUYLASHUMfHyy5o4Hp2FdNqZg1AsUPhfH2kYvEP'),
        grift: new PublicKey('GekTNfm84QfyP2GdAHZ5AgACBRd69aNmgA5FDhZupump'),
        gnz: new PublicKey('FFgfStKwuF3DSxEeogA69FNkPrkb7XDA5Tw29TBEpump'),
        trump: new PublicKey('6p6xgHyF7AeE6TZkSmFsko444wqoP15icUSqi2jfGiPN'),
        melania: new PublicKey('FUAfBo2jgks6gB4Z4LfZkqSZgzNucisEHqnNebaRxM1P'),
        snai: new PublicKey('Hjw6bEcHtbHGpQr8onG3izfJY5DJiWdt7uk2BfdSpump'),
        ava: new PublicKey('DKu9kykSfbN5LBfFXtNNDPaX35o4Fv6vJ9FKk7pZpump'),
        send: new PublicKey('SENDdRQtYMWaQrBroBrJ2Q53fgVuq95CV9UPGEvpCxa'),
        butthole: new PublicKey('CboMcTUYUcy9E6B3yGdFn6aEsGUnYV6yWeoeukw6pump'),
        yne: new PublicKey('7D1iYWfhw2cr9yBZBFE6nZaaSUvXHqG5FizFFEZwpump'),
        arxiv: new PublicKey('7rJp1KhptfCXd6K46bQzaGRYYUboyPE1u6fzEYBKffZB'),
        chatoshi: new PublicKey('Bhu2wBWxfWkRJ6pFn5NodnEvMCqj9DLfCU5qMvt7pump')
    },
    DEVNET: {
        usdc: new PublicKey('DK1gsSV2EubSE5S5FdXHpGzw2cAJNVzxeXRmAfxAMpU5'),
        sol: new PublicKey('B1sL3zxwyVnDGzRWCAsBkjL23wyu8HgwQP4XxgnHiSrv'),
        hnt: new PublicKey('H8UekPGwePSmQ3ttuYGPU1szyFfjZR4N53rymSFwpLPm'),
        jto: new PublicKey('jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL'),
        bonk: new PublicKey('DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263'),
        ray: new PublicKey('4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R'),
        jup: new PublicKey('JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN'),
        pyth: new PublicKey('HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3'),
        render: new PublicKey('rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof'),
        aury: new PublicKey("AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP"),
        honey: new PublicKey("4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy"),
        orca: new PublicKey("orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE"),
        usdt: new PublicKey("Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"),
        wif: new PublicKey("EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm"),
        wen: new PublicKey("WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk"),
        nos: new PublicKey("nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7"),
        shdw: new PublicKey("SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y"),
        w: new PublicKey("85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ"),
        kmno: new PublicKey("KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS"),
        borg: new PublicKey("3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z"),
        mother: new PublicKey("3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN"),
        wxm: new PublicKey("wxmJYe17a2oGJZJ1wDe6ZyRKUKmrLj2pJsavEdTVhPP"),
        zex: new PublicKey("ZEXy1pqteRu3n13kdyh4LwPQknkFk3GzmMYMuNadWPo"),
        bsol: new PublicKey("bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1"),
        jitosol: new PublicKey("J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn"),
        msol: new PublicKey("mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So"),
        cloud: new PublicKey("CLoUDKc4Ane7HeQcPpE3YHnznRxhMimJ4MyaUqyHFzAu"),
        xbg: new PublicKey("XBGdqJ9P175hCC1LangCEyXWNeCPHaKWA17tymz2PrY"),
        aleph: new PublicKey("3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x"),
        vchf: new PublicKey("AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch"),
        veur: new PublicKey("C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519"),
        drift: new PublicKey("DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7"),
        tnsr: new PublicKey("TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6"),
        ponke: new PublicKey("5z3EqYQo9HiCEs3R84RCDMu2n7anpDMxRhdK8PSWmrRC"),
        natix: new PublicKey("FRySi8LPkuByB7VPSCCggxpewFUeeJiwEGRKKuhwpKcX"),
        neon: new PublicKey("NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44"),
        dasha: new PublicKey("FQ1tyso61AH1tzodyJfSwmzsD3GToybbRNoZxUBz21p8"),
        spx6900: new PublicKey("J3NKxxXZcnNiMjKw9hYb2K4LUxgwB6t1FtPtQVsv3KFr"),
        retardio: new PublicKey("6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx"),
        michi: new PublicKey("5mbK36SZ7J19An8jFochhQS4of8g6BwUjbeCSxBSoWdp"),
        selfie: new PublicKey("9WPTUkh8fKuCnepRWoPYLH3aK9gSjPHFDenBq2X1Czdp"),
        hammy: new PublicKey("26KMQVgDUoB6rEfnJ51yAABWWJND8uMtpnQgsHQ64Udr"),
        mini: new PublicKey("2JcXacFwt9mVAwBQ5nZkYwCyXQkRcdsYrDXn6hj22SbP"),
        sigma: new PublicKey("5SVG3T9CNQsm2kEwzbRq6hASqh1oGfjqTtLXYUibpump"),
        mumu: new PublicKey("5LafQUrVco6o7KMz42eqVEJ9LW31StPyGjeeu5sKoMtA"),
        billy: new PublicKey("3B5wuUrMEi5yATD7on46hKfej3pfmd7t1RKgrsN3pump"),
        blze: new PublicKey('BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA'),
        crown: new PublicKey('GDfnEsia2WLAW5t8yx2X5j2mkfA74i5kwGdDuZHt7XmG'),
        grass: new PublicKey('Grass7B4RdKfBCjTKgSqnXkqjwiGvQyFbuSCUJr3XXjs'),
        guac: new PublicKey('AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR'),
        jlp: new PublicKey('27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4'),
        lockin: new PublicKey('8Ki8DpuWNxu9VsS3kQbarsCWMcFGWkzzA8pUPto9zBd5'),
        lst: new PublicKey('LSTxxxnJzKDFSLr4dUkPcmCf5VyryEqzPLz5j4bpxFp'),
        mew: new PublicKey('MEW1gQWJ3nEXg2qgERiKu7FAFj79PHvQVREQUzScPP5'),
        mnde: new PublicKey('MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey'),
        myro: new PublicKey('HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4'),
        ore: new PublicKey('oreoU2P8bN6jkk3jbaiVxYnG1dCXcYxwhwyK9jSybcp'),
        popcat: new PublicKey('7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr'),
        uxd: new PublicKey('7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT'),
        zerebro: new PublicKey('8x5VqbHA8D7NkD52uNuS5nnt3PwA8pLD34ymskeSo2Wn'),
        chillguy: new PublicKey('Df6yfrKC8kZE3KNkrHERKzAetSxbrWeniQfyJY4Jpump'),
        gigasol: new PublicKey('63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9'),
        tookersol: new PublicKey('9EYScpiysGnEimnQPzazr7Jn9GVfxFYzgTEj85hV9L6U'),
        goatsol: new PublicKey('CzLSujWBLFsSjncfkh59rUFqvafWcY5tzedWJSuypump'),
        brettsol: new PublicKey('DxtssVdyYe4wWE5f5zEgx2NqtDFbVL3ABGY62WCycHWg'),
        moodengsol: new PublicKey('ED5nyyWEzpPPiWimP8vYm7sD7TD3LAt3Q3gRTWHzPJBY'),
        trempsol: new PublicKey('FU1q8vJpZNUrmqsciSjp8bAKKidGsLmouB8CBdf8TKQv'),
        harambesol: new PublicKey('Fch1oixTPri8zxBnmdCEADoJW2toyFHxqDZacQkwdvSP'),
        metasol: new PublicKey('METADDFL6wWMWEoKTFJwcThTbUmtarRJZjRpzUvkxhr'),
        borgy: new PublicKey("BorGY4ub2Fz4RLboGxnuxWdZts7EKhUTB624AFmfCgX"),
        bitcoinpotter: new PublicKey('CTgiaZUK12kCcB8sosn4Nt2NZtzLgtPqDwyQyr2syATC'),
        io: new PublicKey('BZLbGTNCSFfoth2GYDtwr7e4imWzpR5jqcUuGEwr646K'),
        me: new PublicKey('MEFNBXixkEbait3xn9bkm8WsJzXtVsaJEn4c8Sam21u'),
        pnut: new PublicKey('2qEHjDLDLbuBgRYvsxhc5D6uDWAivNFZGan56P1tpump'),
        scf: new PublicKey('GiG7Hr61RVm4CSUxJmgiCoySFQtdiwxtqf64MsRppump'),
        usa: new PublicKey('69kdRLyP5DTRkpHraaSZAQbWmAwzF9guKjZfzMXzcbAs'),
        pengu: new PublicKey('2zMMhcVQEXDtdE6vsFS7S7D5oUodfJHE8vd1gnBouauv'),
        aga: new PublicKey('AGAxefyrPTi63FGL2ukJUTBtLJStDpiXMdtLRWvzambv'),
        solid: new PublicKey('SoLiDMWBct5TurG1LNcocemBK7QmTn4P33GSrRrcd2n'),
        chonky: new PublicKey('H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU'),
        moe: new PublicKey('8xzoj8mVmXtBQm6d2euJGFPvQ2QsTV5R8cdexi2qpump'),
        agentSai: new PublicKey('Ag5mMdBSxm8J893RazD9PGkxWKJ5o3UKdWW4ZmJAey9w'),
        dogami: new PublicKey('9o8MnTiZs8i7ahF4MVVW6yEgDso6ksCVXZg4BdcWo8hg'),
        smartdex: new PublicKey('9dzSzFvPsKDoY2gdWErsuz2H1o4tbzvgBhrNZ9cvkD2j'),
        aiPump: new PublicKey('2w3A2P5juwg234spHKfps7WReWoVmujtErqjaZm9VaiP'),
        mates: new PublicKey('MAtesZXcU38mgPuteRFo9ApCauYRRuwn3xf1cs5y7ZX'),
        fartcoin: new PublicKey('9BB6NFEcjBCtnNLFko2FqVQBq8HHM13kCyYcdQbgpump'),
        maga: new PublicKey('HaP8r3ksG76PhQLTqR8FYBeNiQpejcFbQmiHbg787Ut1'),
        pippin: new PublicKey('Dfh5DzRgSvvCFDoYc2ciTkMrbDfRKybA4SoFbPmApump'),
        create: new PublicKey('92crE7qiX5T7VtiXhCeagfo1E81UtyguiXM7qCi7pump'),
        neur: new PublicKey('3N2ETvNpPNAxhcaXgkhKoY1yDnQfs41Wnxsx5qNJpump'),
        griffain: new PublicKey('KENJSUYLASHUMfHyy5o4Hp2FdNqZg1AsUPhfH2kYvEP'),
        grift: new PublicKey('GekTNfm84QfyP2GdAHZ5AgACBRd69aNmgA5FDhZupump'),
        gnz: new PublicKey('FFgfStKwuF3DSxEeogA69FNkPrkb7XDA5Tw29TBEpump'),
        trump: new PublicKey('6p6xgHyF7AeE6TZkSmFsko444wqoP15icUSqi2jfGiPN'),
        melania: new PublicKey('FUAfBo2jgks6gB4Z4LfZkqSZgzNucisEHqnNebaRxM1P'),
        snai: new PublicKey('Hjw6bEcHtbHGpQr8onG3izfJY5DJiWdt7uk2BfdSpump'),
        ava: new PublicKey('DKu9kykSfbN5LBfFXtNNDPaX35o4Fv6vJ9FKk7pZpump'),
        send: new PublicKey('SENDdRQtYMWaQrBroBrJ2Q53fgVuq95CV9UPGEvpCxa'),
        butthole: new PublicKey('CboMcTUYUcy9E6B3yGdFn6aEsGUnYV6yWeoeukw6pump'),
        yne: new PublicKey('7D1iYWfhw2cr9yBZBFE6nZaaSUvXHqG5FizFFEZwpump'),
        arxiv: new PublicKey('7rJp1KhptfCXd6K46bQzaGRYYUboyPE1u6fzEYBKffZB'),
        chatoshi: new PublicKey('Bhu2wBWxfWkRJ6pFn5NodnEvMCqj9DLfCU5qMvt7pump')
    }
};
